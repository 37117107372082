import bleApiHandler from "./bleApiHandler";
import bleCommandHandler from "./bleCommandHandler";
import bleConnectionInitializationHandler from "./bleConnectionInitializationHandler";
import bleResponseHandler from "./bleResponseHandler";
import bleTokenHandler from "./bleTokenHandler";
import bleVariables from "./bleVariables";
export default {
    mixins: [bleApiHandler, bleVariables, bleTokenHandler, bleConnectionInitializationHandler, bleResponseHandler, bleCommandHandler],
    data() {
        return {
            ble: {
                busy: false,
                connectedDeviceId: null,
                connectedDevice: null,
                nusServiceUUID: `6e400001-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharRXUUID: `6e400002-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharTXUUID: `6e400003-b5a3-f393-e0a9-e50e24dcca9e`,
                MTU: 20,
                forceStopProcess: false,
            },
        };
    },
    methods: {
        resetBleDeviceState() {
            this.ble = {
                busy: false,
                connectedDeviceId: null,
                connectedDevice: null,
                nusServiceUUID: `6e400001-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharRXUUID: `6e400002-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharTXUUID: `6e400003-b5a3-f393-e0a9-e50e24dcca9e`,
                MTU: 20,
                forceStopProcess: false,
            };
        },
        // retryBleProcess(reset_retry_number = false) {
        //     const activeCommand = this.bleCommandHandler.activeCommand;
        //     if (reset_retry_number) this.bleCommandHandler.retry = 0;

        //     switch (activeCommand.key) {
        //         case "send_command_to_device":
        //             this.sendBleCommand(activeCommand.bleCommandEnum, activeCommand.bleCommandParams);
        //             break;
        //         default:
        //             console.error("Cannot retry command, please retry the process");
        //             break;
        //     }
        // },
    },
};
