export default {
  data() {
    return {
      selected: [],
      selectAll: false,
    };
  },
  methods: {
    select($evt) {
      let item = $evt.id || $evt.plug_id;
      let select = $evt.selected;
      let index = this.selected.indexOf(item);
      if (select) {
        if (index === -1) this.selected.push(item);
      } else {
        this.selected.splice(index, 1);
      }
      console.log(this.selected);
    },
    selectSwitch() {
      this.selected = [];
      if (this.selectAll) {
        this.userPlugs.forEach((plug) => {
          let item = plug.id || plug.plug_id;
          if (plug.selected) this.selected.push(item);
        });
      }
    },
  },
  watch: {
    selectAll() {
      this.$nextTick(() => {
        this.selectSwitch();
      });
    },
  },
};
