import Api from "@library/apis/Api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      mode: null,
      userAccessModal: false,
      edit: null,
      error: null,
      valid: false,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    revoke() {
      return this.mode === "revoke";
    },
    modalTitle() {
      if (this.mode === "revoke") return "Revoke user access to device?";
      if (this.mode === "edit") return "Edit User Access";
      return "Grant User Access";
    },
  },
  methods: {
    userAction(evt) {
      this.edit = evt;
      this.mode = evt.mode;
      this.userAccessModal = true;
    },
    save() {
      let data;
      if (this.revoke) {
        data = `{"username":"${this.edit.user.username}","plugId":${this.edit.plug.id}}`;
      } else {
        if (!this.edit.user.username || !this.edit.user.plug_payment_access_plan_id) {
          this.error = "Please complete required fields";
          return;
        }
        data = `{"username":"${this.edit.user.username}","plugId":${this.edit.plug.id},"plugPaymentAccessPlanId":${this.edit.user.plug_payment_access_plan_id}}`;
      }

      this.$store.dispatch("loading", true);
      Api.setUserAccessToPlug(this.role, !this.revoke || this.mode === "edit", data)
        .then((data) => {
          this.$store.dispatch("loading", false);
          this.$store.dispatch("snackbar", { message: data });
          this.$store.dispatch("editPlugUser", this.edit.plug.id);
          this.userAccessModal = false;
        })
        .catch((error) => {
          this.$store.dispatch("loading", false);
          this.error =
            error.response.data === "Device has been disabled"
              ? `Device has been disabled due to expired subscription. Please contact customer support using the "Chat With Us" button on the navigation bar.`
              : error.response.data;
        });
    },
  },
  watch: {
    userAccessModal() {
      this.error = false;
    },
    "edit.user.username"() {
      this.$nextTick(() => {
        if (this.edit.user && this.edit.user.username) {
          this.edit.user.username = String(this.edit.user.username.replace(" ", "").toLowerCase());
        }
      });
    },
  },
};
