import Api from "@library/apis/Api";
export default {
  data() {
    return {
      bleToken: null,
    };
  },
  methods: {
    getBleToken(plug_identifier, role = "owner", storage_key = "__plugzio_storage_owner_ble_credentials__") {
      return new Promise(async (resolve, reject) => {
        console.groupCollapsed("Getting token for connecting BLE");

        const tokenFromApi = await this.renewBleToken(plug_identifier, role);
        if (tokenFromApi) {
          console.log("Successfully get token");
          console.groupEnd();
          resolve(tokenFromApi.token);
          return;
        }

        console.log("Failed to get token, trying get token from storage");

        const tokenStorage = JSON.parse(localStorage.getItem(storage_key));
        const email = localStorage.getItem("email");
        if (!tokenStorage || !tokenStorage.hasOwnProperty(email) || !tokenStorage[email].hasOwnProperty(plug_identifier)) {
          console.log("Token from storage not found");
          console.groupEnd();
          reject("Failed to get token, please connect to internet to continue");
          return;
        }
        const token = tokenStorage[email][plug_identifier];
        if (this.isBleTokenExpired(token)) {
          console.log("Token from storage expired");
          console.groupEnd();
          reject("Token is expired, please connect to internet to renew token");
          this.$store.dispatch("setOwnerBleCredential", { deviceId: plug_identifier, credential: null });
          return;
        }
        console.log("Using token from storage");
        console.groupEnd();
        resolve(token);
      });
    },
    async getBleTokenFromApi(device_id, role = "user") {
      if (role == "owner") return await this.getOwnerBleToken(device_id);
      else return await this.getUserBleToken(device_id);
    },
    isBleTokenExpired(token) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expiry = token.hasOwnProperty("expiry") ? token.expiry : -1;
      const expiredWithin = (expiry - currentTime) / 3600;
      if (expiredWithin < 0) {
        return true;
      }
      return false;
    },
    async isBleTokenUsedRecently(device_id, get_session_data = false, sessionLogs = []) {
      if (get_session_data && navigator.onLine) {
        console.groupCollapsed("Getting 30 days session logs for checking BLE token");
        const time = {
          since: this.$moment()
            .subtract(30, "days")
            .startOf("day")
            .unix(),
          till: this.$moment()
            .endOf("day")
            .unix(),
        };
        await Api.sessions("user", `{"since":${time.since},"till":${time.till}}`)
          .then((data) => {
            console.log("Successfully get session logs");
            sessionLogs = data;
          })
          .catch((error) => {
            console.log(error);
            console.log("Failed getting session logs. Using existing session logs.");
          });
        console.log(sessionLogs);
        console.groupEnd();
      }
      const tokenUsedSessions = sessionLogs.filter((sessionLog) => sessionLog.plug_identifier == device_id);
      if (tokenUsedSessions.length > 0) return true;
      return false;
    },
    isBleTokenExpiringSoon(token, expiringSoonThreshold = 12) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expiry = token.hasOwnProperty("expiry") ? token.expiry : -1;
      const expiredWithin = (expiry - currentTime) / 3600;
      if (expiredWithin < 0) {
        return false;
      }
      if (expiredWithin <= expiringSoonThreshold) return true;
      return false;
    },
    async renewBleToken(device_id, role = "user") {
      let returns = {
        error: false,
        message: "",
        token: null,
      };
      if (!navigator.onLine) {
        return {
          error: true,
          message: "Failed to get token, please connect to internet to continue",
          token: null,
        };
      }
      await this.getBleTokenFromApi(device_id, role)
        .then((tokenReponse) => {
          returns.token = tokenReponse;
        })
        .catch((err) => {
          let message = "Failed to get token, please check your device id";
          if (err.response.status == 400) {
            message = err.response.data;
            console.log(message);
          }
          returns = {
            error: true,
            message: message,
            token: null,
          };
        });
      return returns;
    },
  },
};
