import { mapState, mapGetters } from "vuex";
import Api from "./../library/apis/Api";
import SessionHelpers from "../library/helpers/SessionHelpers";

export default {
  data() {
    return {
      sessionsLoading: false,
      sessionInterval: null,
      sessionsLoaded: false,
      stats: null,
    };
  },
  computed: {
    ...mapState({
      adminActiveView: (state) => state.Admin.activeView,
      managerview: (state) => state.Admin.managerview,
      role: (state) => state.Auth.role,
    }),
    ...mapGetters(["storedTime"]),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    sessions() {
      const sessions = JSON.parse(JSON.stringify(this.$store.getters.sessions));
      return SessionHelpers.filter(sessions);
    },
  },
  watch: {
    sessions() {
      const stats = SessionHelpers.getStats(this.sessions);
      this.stats = stats;
    },
    stats() {
      this.$store.dispatch("setStats", this.stats);
    },
  },
  methods: {
    getSessions() {
      this.$nextTick(() => {
        if (!this.storedTime.since || !this.storedTime.since) return false;

        this.sessionsLoading = true;
        this.sessionsLoaded = false;
        const apiPrefix = this.adminActiveView == "managerview" ? "admin/owner" : this.adminActiveView == "userview" ? "admin/user" : this.role;
        const username = this.role == "admin" && this.adminActiveView == "userview" ? this.adminViewAsUser.username : this.role == "admin" && this.adminActiveView == "managerview" ? this.managerview.username : null;
        Promise.all([Api.sessions(apiPrefix, { since: this.storedTime.since, till: this.storedTime.till, username }), Api.wallets(apiPrefix, { username })])
          .then(([sessions, wallets]) => {
            this.$store.dispatch("setWallets", wallets || []);
            this.$store.dispatch("setSessions", sessions || []);
            if (!sessions || sessions.length == 0) this.sessionsLoaded = "No recent sessions";
          })
          .catch((err) => {
            if (this.$route.name === "sessions") this.$store.dispatch("snackbar", { message: "Could not complete requests..." });
          })
          .finally(() => (this.sessionsLoading = false));
      });
    },
    clearCounters() {
      clearInterval(this.sessionInterval);
    },
    initSessions() {
      if (!this.sessionInterval) {
        this.sessionInterval = setInterval(() => {
          this.getSessions();
        }, SESSION_REFRESH_INTERVAL);
      }
    },
  },
  beforeDestroy() {
    this.clearCounters();
  },
};
