export default {
    data() {
        return {
            bleConnection: {
                forceConnectManually: false,
                nusService: null,
                rxCharacteristic: null,
                txCharacteristic: null,
            },
        };
    },
    computed: {},
    methods: {
        async connectToBleDevice(deviceId) {
            let _this = this;
            const device_name = `Plugzio-` + deviceId;
            return new Promise(async (resolve, reject) => {
                if (!navigator.bluetooth) {
                    console.error("Bluetooth or WebBluetooth API not enabled");
                    reject(
                        "Bluetooth or WebBluetooth API is not available on your browser. Please make sure the Bluetooth and Web Bluetooth flag is enabled."
                    );
                    return;
                }

                console.log(`Searching ${device_name}...`);

                if (_this.ble.connectedDevice && deviceId != _this.ble.connectedDeviceId) _this.ble.connectedDevice.device.gatt.disconnect();
                if (_this.ble.connectedDevice) {
                    resolve(device_name + " is already connected.");
                    return;
                }

                this.bleConnection.forceConnectManually ? connectManually() : connectAutomatically();
                this.bleConnection.forceConnectManually = false;

                function onAdvertisementReceived(event) {
                    if (event.name != device_name) return;
                    const device = event.device;
                    if (device.gatt.connected) {
                        device.removeEventListener("advertisementreceived", onAdvertisementReceived);
                        console.log(device_name + " is connected");
                        return;
                    }
                    if (_this.ble.busy) return;
                    connectToSelectedDevice(device)
                        .then((response) => resolve(response))
                        .catch((error) => reject(error));
                }

                async function connectAutomatically() {
                    try {
                        await navigator.bluetooth
                            .getDevices({ filters: [{ name: device_name }], optionalServices: [_this.ble.nusServiceUUID] })
                            .then(async (devices) => {
                                let device_found = false;
                                let index = 0;
                                for (const device of devices) {
                                    if (device.name != device_name) continue;
                                    console.log("Found " + device.name);
                                    device_found = true;
                                    let is_error = false;
                                    await device.watchAdvertisements().catch((error) => {
                                        console.error(error.message);
                                        switch (error.message) {
                                            case "Bluetooth adapter not available.":
                                                reject(`Bluetooth adapter not available. Please check your bluetooth is turned on and try again.`);
                                                break;
                                            default:
                                                reject(error.message);
                                                break;
                                        }
                                        is_error = true;
                                    });
                                    if (is_error) return;
                                    device.addEventListener("advertisementreceived", onAdvertisementReceived);
                                    index++;
                                    setTimeout(async function() {
                                        if (_this.ble.connectedDevice) {
                                            resolve(device_name + " is connected.");
                                            return;
                                        }
                                        if (!_this.ble.busy) {
                                            device.removeEventListener("advertisementreceived", onAdvertisementReceived);
                                            await connectToSelectedDevice(device)
                                                .then((response) => resolve(response))
                                                .catch((error) => reject(error));
                                        }
                                        return;
                                    }, index * 10000);
                                }
                                setTimeout(function() {
                                    if (!device_found) {
                                        reject(
                                            `You are attempting to pair to a new device with Device ID "${device_name}". Please select the device from the list of available devices.`
                                        );
                                        _this.bleConnection.forceConnectManually = true;
                                    }
                                    return;
                                }, 10000);
                            })
                            .catch((error) => {
                                console.error(error);
                                reject("Error getting device");
                            });
                    } catch (error) {
                        console.error(error);
                        reject("Failed to automatically connect to bluetooth device, click Next to try connect manually");
                        _this.bleConnection.forceConnectManually = true;
                    }
                }

                function connectManually() {
                    console.log(`Scanning bluetooth with "Plugzio-" name prefix...`);
                    navigator.bluetooth
                        .requestDevice({ filters: [{ namePrefix: "Plugzio-" }], optionalServices: [_this.ble.nusServiceUUID] })
                        .then(async (device) => {
                            device.removeEventListener("advertisementreceived", onAdvertisementReceived);
                            await connectToSelectedDevice(device)
                                .then((response) => resolve(response))
                                .catch((error) => reject(error));
                        })
                        .catch((error) => {
                            console.error(error);
                            reject(`User cancelled bluetooth request`);
                        });
                }

                async function connectToSelectedDevice(device) {
                    return await new Promise(async (resolveConnectToSelectedDevice, rejectConnectToSelectedDevice) => {
                        if (_this.ble.busy) {
                            rejectConnectToSelectedDevice("Device still busy");
                            return;
                        }
                        await new Promise((resolveConnectPromise, rejectConnectPromise) => {
                            _this.ble.busy = true;
                            console.log("Connecting to " + device_name);
                            resolveConnectPromise(device.gatt.connect());
                        })
                            .then((server) => server.getPrimaryService(_this.ble.nusServiceUUID))
                            .then((service) => (_this.bleConnection.nusService = service))
                            .then(() => _this.bleConnection.nusService.getCharacteristic(_this.ble.nusCharRXUUID))
                            .then((characteristic) => (_this.bleConnection.rxCharacteristic = characteristic))
                            .then(() => _this.bleConnection.nusService.getCharacteristic(_this.ble.nusCharTXUUID))
                            .then((characteristic) => (_this.bleConnection.txCharacteristic = characteristic))
                            .then(() => _this.bleConnection.txCharacteristic.startNotifications())
                            .then(() => {
                                _this.bleConnection.txCharacteristic.addEventListener("characteristicvaluechanged", _this.handleBleNotifications);
                                _this.ble.connectedDeviceId = deviceId;
                                _this.ble.connectedDevice = device;
                                console.log(device_name + " Connected.");
                                device.removeEventListener("advertisementreceived", onAdvertisementReceived);
                                resolveConnectToSelectedDevice(device_name + " Connected.");
                            })
                            .catch((error) => {
                                console.error(error);

                                if (_this.ble.connectedDevice && _this.ble.connectedDevice.gatt.connected)
                                    _this.ble.connectedDevice.gatt.disconnect();

                                rejectConnectToSelectedDevice(
                                    `Failed to connect to Device ${deviceId}. Please make sure the device is on and you are close proximity to the device. Please refresh the site and retry again.`
                                );
                            })
                            .finally(() => {
                                _this.ble.busy = false;
                            });
                    });
                }
            });
        },
    },
};
