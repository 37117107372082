import lapse from "./../mixins/lapse";

export default {
    mixins: [lapse],
    data() {
        return {
            error: [],
            timeSpan: {
                since: this.$moment()
                    .subtract(30, "days")
                    .startOf("day")
                    .unix(),
                till: this.$moment()
                    .endOf("day")
                    .unix(),
            },
            time: {
                now: Math.round(new Date().getTime()),
                interval: null,
            },
            contactUs: "mailto:customercare@parkizio.com?subject=Question&body=Name%3A%0A%0APhone%20Number%3A%0A%0AQuestion%3A%0A%0A",
        };
    },
    computed: {
        storeTime() {
            return this.$store.state.time;
        },
    },
    methods: {
        formatAmount(amount, currency = null) {
            return (currency ? currency + " " : "") + this.formatNumber(parseFloat(amount).toFixed(2));
        },
        formatConsumption(consumption) {
            return consumption ? this.formatNumber((consumption / 1000).toFixed(3)) + " kWh" : 0 + " kWh";
        },
        formatCost(cost, currency = null) {
            if (cost === null) cost = 0;
            return cost === 0 ? "FREE" : this.formatAmount(cost, currency);
        },
        formatDateTime(timespan) {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let date_ob = new Date(timespan * 1000);
            let date = ("0" + date_ob.getDate()).slice(-2);
            let month = date_ob.getMonth();
            let year = date_ob.getFullYear();
            let hours = ("0" + date_ob.getHours()).slice(-2);
            let minutes = ("0" + date_ob.getMinutes()).slice(-2);
            let seconds = ("0" + date_ob.getSeconds()).slice(-2);
            return date + "-" + monthNames[month] + "-" + year + " " + hours + ":" + minutes + ":" + seconds;
        },
        formatRate(rate, unit = "h", currency = null) {
            if (rate === null) rate = 0;
            if (unit != "" && unit != null) unit = " / " + unit;
            return rate === 0 ? "FREE" : this.formatAmount(parseFloat(rate), currency) + unit;
        },
        formatNumber(num) {
            // return num.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,') || 0
            if (!num) return 0;
            var parts = num.toString().split(".");
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        },
        isJsonStringValid(json_string) {
            try {
                JSON.parse(json_string);
            } catch (e) {
                return false;
            }
            return true;
        },
        prepareString(s) {
            let valArr = new Uint8Array(s.length);
            for (let i = 0; i < s.length; i++) {
                let val = s[i].charCodeAt(0);
                valArr[i] = val;
            }
            return valArr;
        },
        sort(arr, key) {
            return arr.sort((a, b) => {
                return a[key] - b[key];
            });
        },
        splitString(string, size) {
            let re = new RegExp(".{1," + size + "}", "g");
            return string.match(re);
        },
    },
    mounted() {
        this.time.interval = setInterval(() => {
            this.time.now = Math.round(new Date().getTime());
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.time.interval);
    },
};
