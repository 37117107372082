import helpers from "./helpers";
import { mapState } from 'vuex';

export default {
  mixins: [helpers],
  computed: {
    ...mapState({
      timezone: (state) => state.Global.timezone
    })
  },
  methods: {
    formatSessions(sessions, plugs, wallets) {
      return sessions.map((o) => {
        let newEndtime = o.endtime !== null;

        o.active = !newEndtime;
        o.status = !newEndtime ? "ON" : "OFF";
        o.statusCode = !newEndtime ? 1 : 0;
        o.class = !newEndtime ? "plug-on-color" : "plug-off-color";

        if (!newEndtime) {
          o.updated = o.last_update_timestamp ? o.last_update_timestamp : o.starttime;
        } else {
          o.updated = o.endtime;
        }

        o.lapse = !newEndtime ? this.lapse(this.$moment.unix(o.starttime), this.$moment.unix(o.updated)) : "00:00:00 AM";
        o.duration = !newEndtime ? o.lapse : this.lapse(this.$moment.unix(o.starttime), this.$moment.unix(o.endtime));
        o.duration_raw = !newEndtime ? o.updated - o.starttime : o.endtime - o.starttime;
        o.consumption = this.formatConsumption(o.total_consumption);
        o.amount = o.total_cost === 0 ? "0.00" : this.formatAmount(o.total_cost);
        o.starttime_formatted = this.$moment.unix(o.starttime).format("DD-MMM-YYYY hh:mm:ss A");
        o.endtime_formatted = newEndtime ? this.$moment.unix(o.endtime).format("DD-MMM-YYYY hh:mm:ss A") : null;
        o.update_formatted = this.ago(o.updated);
        const start_date = this.$moment.unix(o.starttime).tz(this.timezone).format("DD");
        const end_date = this.$moment.unix(o.endtime).tz(this.timezone).format("DD");
        o.graph_timeformat = "h:mm:ss A";
        if (start_date != end_date) {
          o.graph_timeformat = "MMM D - h:mm:ss A";
        }

        if (plugs) {
          o.device = o.plug = this.plugs.find((plug) => {
            return plug.id === o.plug_id;
          });
          o.plug_identifier = o.device ? o.device.identifier : "";
          o.description = o.plug ? o.plug.description : "";
        } else {
          o.timeline = [
            {
              tag: "START",
              content: this.$moment.unix(o.starttime).format("DD-MMM-YYYY hh:mm:ss A"),
              class: o.class,
              icon: "power",
            },
            {
              tag: newEndtime ? "END" : "UPDATED",
              content: newEndtime ? this.$moment.unix(o.updated).format("DD-MMM-YYYY hh:mm:ss A") : this.$moment().format("DD-MMM-YYYY hh:mm:ss A"),
              class: o.class,
              icon: newEndtime ? "power_off" : "power",
            },
          ];
        }

        try {
          if (o.plug_payment_access_plan) {
            o.plug_payment_access_plan = JSON.parse(o.plug_payment_access_plan);
          }
        } catch (e) {}

        return o;
      });
    },
  },
};
