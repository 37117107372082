export default {
  methods: {
    async sendBleCommand(commandEnum, commandParams = {}, loading = null, realm = 2, timeout = 1000) {
      console.groupEnd();
      console.groupEnd();
      if (this.bleActivation.forceStopProcess) {
        console.log("BLE process cancelled");
        return;
      }
      var _this = this;
      function checkDeviceReady() {
        return new Promise((resolve, reject) => {
          if (!_this.bleActivation.deviceConnected) {
            _this.$store.dispatch("setBleActivationModal", {
              error: "Device is not connected, please connect to device and retry",
              loading: null,
              okButton: "ok",
              cancelButton: null,
            });
            reject({ msg: "Device is not connected", error_key: "not_connected" });
            return;
          }
          if (_this.bleActivation.busy) {
            reject({ msg: "Device is busy", error_key: "busy" });
            return;
          }
          resolve("Device is ready");
        });
      }

      const is_device_ready = await checkDeviceReady()
        .then(() => {
          if (loading) _this.$store.dispatch("setBleActivationModal", { loading: loading, okButton: null, cancelButton: null });
          return true;
        })
        .catch((error) => {
          // console.log(error.msg);
          if (error.error_key == "busy") setTimeout(() => _this.sendBleCommand(commandEnum, commandParams, loading), 1000);
          return false;
        });
      if (!is_device_ready) return;
      this.$store.dispatch("setBleActivationActiveCommand", {
        key: "send_command_to_device",
        message: loading,
        bleCommandEnum: commandEnum,
        bleCommandParams: commandParams,
      });
      const selectedCommand = Object.keys(this.bleCommands).find((key) => this.bleCommands[key] === commandEnum);
      console.groupCollapsed(`running command: ${selectedCommand} , params: ${JSON.stringify(commandParams)}`);
      let commandObj = {
        realm: realm,
        id: this.bleActivation.tokens.userId,
        expiry: this.bleActivation.tokens.expiry,
        token: this.bleActivation.tokens.token,
        paymentPlanID: this.bleActivation.tokens.papSnapshotId,
        command: commandEnum,
      };
      for (const property in commandParams) {
        commandObj[property] = commandParams[property];
      }
      console.log(commandObj);

      this.$store.dispatch("setBleActivation", { busy: true });
      this.$store.dispatch("setBleResponse", { response: "", obj: "" });

      let command_string = JSON.stringify(commandObj);
      let command = this.splitString(command_string, 18);
      let command_chunks = command.map((c, i) => (i == command.length - 1 ? "2" : "1") + c);
      command_chunks.unshift("0");
      const max_retry = window.ble_max_retries ? window.ble_max_retries : 3;
      console.groupCollapsed("Sending commands to device...");
      this.sendCommandToBleDevice(command_chunks)
        .then(() => {
          console.groupEnd();
          _this.getBleResponse(commandObj);
        })
        .catch((error) => {
          console.error("[" + new Date().getTime() + "] " + error);
          console.groupEnd();
          if (this.bleActivation.forceStopProcess) return;
          if (this.bleActivation.retry < max_retry) {
            console.groupEnd();
            console.warn("Retry to run BLE process, retry #" + (this.bleActivation.retry + 1));
            this.$store.dispatch("setBleActivation", { retry: this.bleActivation.retry + 1 });
            this.retryBleProcess();
          } else this.$store.dispatch("setBleActivationModal", { loading: null, okButton: "retry", cancelButton: "cancel", error: error });
        })
        .finally(() => {
          this.$store.dispatch("setBleActivation", { busy: false });
          console.groupEnd();
        });
    },
    sendCommandToBleDevice(command_chunks) {
      const timeout = window.ble_timeout ? window.ble_timeout : 3000;
      var _this = this;
      console.log(`Timeout for each packets (send and receive) : ${timeout}ms`);
      console.log(`Timeout for receiving packets : ` + (timeout - 500) + "ms");
      const promiseTimeout = function(ms, promise, error_msg = "") {
        let timeout = new Promise((resolve, reject) => {
          let id = setTimeout(() => {
            clearTimeout(id);
            // reject(ms + "ms timeout reached. " + error_msg);
            reject("Send packets timeout reached, " + error_msg);
          }, ms);
        });
        return Promise.race([promise, timeout]);
      };

      function writeToCharacteristic(chunk) {
        console.log("[" + new Date().getTime() + "] Sending packet : " + chunk);
        return new Promise((promiseResolve, promiseReject) => {
          const resolve = promiseResolve;
          const reject = promiseReject;

          if (!_this.bleActivation.device || !_this.bleActivation.device.gatt.connected) {
            reject("Device not connected, process aborted");
          }
          _this.$store.dispatch("setBleResponse", { response: "" });
          _this.$nextTick(() => {
            _this.bleActivation.rxCharacteristic
              .writeValue(_this.prepareString(chunk))
              .then(async () => {
                let stop_execution = false;
                function getResponse() {
                  return new Promise((getResponseResolve, getResponseReject) => {
                    setTimeout(() => {
                      if (_this.bleActivation.forceStopProcess) {
                        getResponseReject("BLE process cancelled");
                        return;
                      }
                      if (stop_execution) return;
                      if (!_this.bleResponse.response) {
                        console.warn("[" + new Date().getTime() + `] Packet ${chunk} got no response, retry getting response...`);
                        getResponse()
                          .then((response) => getResponseResolve(response))
                          .catch((error) => getResponseReject(error));
                        return;
                      }
                      try {
                        const isBleResponseValid = !(_this.bleResponse == "" || !_this.isJsonStringValid(_this.bleResponse));
                        if (isBleResponseValid) {
                          let res = JSON.parse(_this.bleResponse.response.replace(/\0/g, ""), true);
                          if (res.status !== 0) {
                            if (res.status !== 8) {
                              getResponseReject("Packet sent error");
                              return;
                            }
                          }
                        }
                      } catch (e) {
                        getResponseReject("Packet sent error. Error : " + e);
                        return;
                      }
                      getResponseResolve(_this.bleResponse.response);
                    }, 100);
                  });
                }

                promiseTimeout(timeout - 500, getResponse(), "no response from device.")
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    stop_execution = true;
                    reject(error);
                  });
              })
              .catch((err) => reject(err));
          });
        });
      }

      return new Promise((resolve, reject) => {
        let index = -1;
        function sendNext() {
          index++;
          if (index < command_chunks.length) {
            if (_this.bleActivation.forceStopProcess) reject("BLE process cancelled");
            promiseTimeout(timeout, writeToCharacteristic(command_chunks[index]), "error sending packets to device.")
              .then((response) => {
                setTimeout(() => sendNext(), 100); // delay send command, sometimes device not getting the command if send without delay
              })
              .catch((error) => reject(error));
          } else {
            resolve("Success to send commands");
          }
        }
        sendNext();
      });
    },
  },
};
