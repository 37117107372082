export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "This field is required",
        subMode: (value) => value === 0 || value === 1 || "This field is required",
        months: (value) => value > 0 || "Must be 1 month or more",
        oneOrMore: (value) => value > 0 || "Must be 1 or more",
        email: (value) => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Please provide a valid email address";
          } else {
            return "";
          }
        },
        password: (value) => {
          if (value) {
            const pattern = /^(?=.*?[A-Z])(?=(.*[a-z0-9]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
            return pattern.test(value) || "Must be 8 or more, at least 1 capital and 1 special character";
          } else {
            return "";
          }
        },
        withValue: (value) => {
          if (value) {
            return value > 0 || "Please enter an amount greater than $0.00";
          } else {
            return "Please enter an amount greater than $0.00";
          }
        },
        allowZero: (value) => {
          if (value === null) value = 0;
          return parseFloat(value) >= 0 || "Negative value not allowed";
        },
        maxCharacter: (value, max_character = 1000) => {
          if (!value) return "";
          return value.length <= max_character || "Message too long";
        },
        minNumber: (value, min_number = 0) => {
          if (!value) return "";
          return parseInt(value) > min_number || `Number must be bigger than ${min_number}.`;
        },
        minNumberOne: (value, min_number = 1) => {
          if (!value) return "";
          return parseInt(value) >= min_number || `Number must be bigger than ${min_number}.`;
        },
        maxNumber: (value, max_number = 1000) => {
          if (!value) return "";
          return parseInt(value) < max_number || `Number must be smaller than ${max_number}.`;
        },
        number: (value) => {
          if (!value) return "";
          return !isNaN(value) || "Invalid number";
        },
        twoDecimalNumber(value) {
          const decimalPattern = /^-?\d+(\.\d{1,2})?$/;
          if (!decimalPattern.test(value)) {
            return 'Must be a number with exactly two decimal places.';
          }
          return true;
        },
        integerNumber(value) {
          const integerPattern = /^-?\d+$/; 
          if (!integerPattern.test(value)) {
            return 'Must be an integer (no decimal places allowed).';
          }
          return true;
        },
      },
    };
  },
};
