export default {
  data() {
    return {
      variables: {
        networks: {
          channels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          encryptions: [
            {
              name: "AES",
              data: 0,
            },
            {
              name: "TKIP",
              data: 1,
            },
            {
              name: "AES/TKIP",
              data: 2,
            },
          ],
          securities: [
            {
              name: "Unsecured",
              data: 0,
            },
            {
              name: "WEP",
              data: 1,
            },
            {
              name: "WPA-Personal",
              data: 2,
            },
            {
              name: "WPA2-Personal",
              data: 3,
            },
            {
              name: "WPA Enterprise",
              data: 4,
            },
            {
              name: "WPA2 Enterprise",
              data: 5,
            },
          ],
        },
        sessions: {
          statusColors: ["gray", "green", "black"],
        },
      },
    };
  },
};
