export default {
  data() {
    return {
      nusService: null,
      qr_error: null,
    };
  },
  computed: {},
  methods: {
    connectToBleDevice(plug_identifier) {
      if (!navigator.bluetooth) {
        this.$store.dispatch("setBleActivationModal", {
          loading: null,
          error: "Bluetooth or WebBluetooth API is not available on your browser. Please make sure the Bluetooth and Web Bluetooth flag is enabled.",
          okButton: "ok",
          cancelButton: null,
        });
        console.error("Bluetooth or WebBluetooth API not enabled");
        return;
      }
      // navigator.bluetooth.getAvailability().then((available) => {
      //     console.log(available);
      //     if (available) console.log("This device supports Bluetooth!");
      //     else console.log("Doh! Bluetooth is not supported");
      // });
      let _this = this;
      let verify_connection = true;
      const device_name = `Plugzio-` + plug_identifier;
      if (plug_identifier != this.bleActivation.connectedPlugIdentifier && this.bleActivation.deviceConnected) this.bleActivation.device.gatt.disconnect();
      this.$store.dispatch("setBleActivation", {
        plug_identifier: plug_identifier,
        tokens: this.storedBleCredential,
        connectedPlugIdentifier: null,
      });
      this.$store.dispatch("setBleActivationModal", { loading: `Connecting to ${device_name}...` });
      if (this.bleActivation.deviceConnected) {
        verify_connection = false;
        onDeviceConnected();
      }

      this.$store.dispatch("setBleActivation", { busy: true });

      function connectAutomatically() {
        console.log(`Searching ${device_name}...`);
        try {
          navigator.bluetooth
            .getDevices({ filters: [{ name: device_name }], optionalServices: [_this.ble.nusServiceUUID] })
            .then(async (devices) => {
              let device_found = false;
              let index = 0;
              for (const device of devices) {
                if (device.name != device_name) continue;
                console.log("Found " + device.name);
                console.log(device);
                device_found = true;
                let is_error = false;
                await device.watchAdvertisements().catch((error) => {
                  console.error(error.message);
                  switch (error.message) {
                    case "Bluetooth adapter not available.":
                      _this.$store.dispatch("setBleActivationModal", {
                        error: `Bluetooth adapter not available. Please check your bluetooth is turned on and try again.`,
                        loading: null,
                        okButton: "ok",
                        cancelButton: null,
                      });
                      break;
                  }
                  is_error = true;
                });
                if (is_error) return;
                device.addEventListener("advertisementreceived", onAdvertisementReceived);
                index++;
                setTimeout(function() {
                  if (!_this.bleActivation.deviceConnected) {
                    device.removeEventListener("advertisementreceived", onAdvertisementReceived);
                    connectToSelectedDevice(device);
                  }
                }, index * 10000);
              }
              setTimeout(function() {
                if (!device_found) {
                  _this.$store.dispatch("setBleActivationModal", {
                    message: `Device ID: "${device_name}" <br><br>You are attempting to pair to a new device. Please select the device from the list of available devices.`,
                    loading: null,
                    okButton: "connectmanually",
                    cancelButton: "cancel",
                  });
                  _this.$store.dispatch("setBleActivation", { forceConnectManually: true, plug_identifier: plug_identifier });
                }
                return;
              }, 10000);
            })
            .catch((error) => {
              console.log("get device error");
              console.error(error);
            });
        } catch (error) {
          console.error(error);
          _this.$store.dispatch("setBleActivation", { forceConnectManually: true, plug_identifier: plug_identifier });
          _this.$store.dispatch("setBleActivationModal", {
            loading: null,
            error: "Failed to automatically connect to bluetooth device, click Next to try connect manually",
            okButton: "connectmanually",
            cancelButton: "cancel",
          });
        }
      }

      function connectManually() {
        console.log(`Scanning bluetooth with name ${device_name}...`);
        navigator.bluetooth
          .requestDevice({ filters: [{ name: device_name }], optionalServices: [_this.ble.nusServiceUUID] })
          .then((device) => {
            if (device.name != device_name) {
              _this.$store.dispatch("setBleActivationModal", { loading: null, error: `Device ${device_name} not selected` });
              _this.$store.dispatch("setBleActivation", { busy: false });
              return;
            }
            if (!_this.bleActivation.deviceConnected) {
              device.removeEventListener("advertisementreceived", onAdvertisementReceived);
              connectToSelectedDevice(device);
            }
          })
          .catch((error) => {
            console.error(error);
            _this.$store.dispatch("setBleActivationModal", {
              loading: null,
              error: `User cancelled bluetooth request`,
              cancelButton: null,
              okButton: "ok",
            });
            _this.$store.dispatch("setBleActivation", { busy: false });
          })
          .finally(() => {
            _this.$store.dispatch("setBleActivation", { forceConnectManually: false });
          });
      }

      function onAdvertisementReceived(event) {
        const device = event.device;
        if (device.gatt.connected || _this.bleActivation.forceStopProcess) {
          console.log("Advertisement received: Connection Initialization Cancelled");
          device.removeEventListener("advertisementreceived", onAdvertisementReceived);
          return;
        }
        if (event.name != device_name || _this.bleActivation.busy) return;

        connectToSelectedDevice(device);
      }

      function onDeviceConnected() {
        console.log(device_name + " Connected.");
        if (_this.bleActivation.forceStopProcess) {
          device.removeEventListener("advertisementreceived", onAdvertisementReceived);
          console.log("Device Connected: Connection Initialization Cancelled");
          this.$store.dispatch("setBleActivation", { busy: false });
          return;
        }
        _this.bleActivation.device.addEventListener("gattserverdisconnected", _this.onBleDeviceDisconnected);
        _this.$store.dispatch("setBleActivation", { deviceConnected: true, busy: false, connectedPlugIdentifier: plug_identifier });
        if ([_this.bleProcesses.STOP_SESSION, _this.bleProcesses.REMOVE_DEVICE].includes(_this.bleActivation.activeProcess) || !verify_connection) {
          _this.sendBleCommand(_this.bleCommands.DEVICE_GET_CURRENT_STATE, {}, "Checking Device State");
          return;
        }
        _this.sendBleCommand(_this.bleCommands.VERIFY_CONNECTION, {}, "Verifying Connection...");
      }

      function connectToSelectedDevice(device) {
        _this.$store.dispatch("setBleActivationActiveCommand", { key: "connection_initialization" });
        _this.$store.dispatch("setBleActivation", { busy: true });
        _this.$store.dispatch("setBleActivationModal", { error: null });
        if (_this.bleActivation.forceStopProcess) {
          console.log("Connect to Selected Device: Connection Initialization Cancelled");
          return;
        }
        new Promise((resolve, reject) => {
          _this.$store.dispatch("setBleActivation", { device: device });
          console.log("Connecting to " + device_name);
          resolve(device.gatt.connect());
        })
          .then((server) => server.getPrimaryService(_this.ble.nusServiceUUID))
          .then((service) => (_this.nusService = service))
          .then(() => _this.nusService.getCharacteristic(_this.ble.nusCharRXUUID))
          .then((characteristic) => _this.$store.dispatch("setBleActivation", { rxCharacteristic: characteristic }))
          .then(() => _this.nusService.getCharacteristic(_this.ble.nusCharTXUUID))
          .then((characteristic) => (_this.txCharacteristic = characteristic))
          .then(() => _this.txCharacteristic.startNotifications())
          .then(() => {
            _this.txCharacteristic.addEventListener("characteristicvaluechanged", _this.handleBleNotifications);
            onDeviceConnected();
          })
          .catch((error) => {
            _this.$store.dispatch("setBleActivation", { busy: false });
            _this.$nextTick(() => {
              console.log(error);
              _this.$store.dispatch("setBleActivationModal", {
                title: "Connecting To Device",
                error: `Failed to connect to Device ${plug_identifier}. Please make sure the device is on and you are close proximity to the device. Please refresh the site and retry again.`,
                loading: false,
                cancelButton: null,
                okButton: "ok",
              });
              if (String(error).match(/User cancelled/gi)) {
              } else _this.error = error;

              if (_this.bleActivation.device && _this.bleActivation.device.gatt.connected) _this.bleActivation.device.gatt.disconnect();
            });
          });
      }

      if (!this.bleActivation.deviceConnected) {
        this.bleActivation.forceConnectManually ? connectManually() : connectAutomatically();
      }
    },
    getBleDeviceToken(plug_identifier) {
      this.$store.dispatch("setBleActivation", { plug_identifier: plug_identifier });
      return new Promise(async (resolve, reject) => {
        console.groupCollapsed("Getting token for BLE Activation");
        const tokenFromApi = await this.renewBleToken(plug_identifier);
        if (!tokenFromApi.error) {
          console.log("Successfully get token");
          console.log(tokenFromApi.token);
          console.groupEnd();
          this.$store.dispatch("setBleActivation", { tokens: tokenFromApi.token });
          resolve(tokenFromApi.token);
          return;
        }
        if (
          tokenFromApi.message.includes("Access to this device is restricted by Property Management. For access, please contact Property Management.") ||
          tokenFromApi.message.includes("User has a wallet with negative balance")
        ) {
          console.log(tokenFromApi.message);
          console.groupEnd();
          reject(tokenFromApi.message);
          return;
        }
        console.log("Failed to get token, trying get token from storage");
        if (!this.storedBleCredential) {
          console.log("Token from storage not found");
          console.groupEnd();
          // reject("Failed to get token, please connect to internet to continue");
          reject("You may not have access to this device, please try activating the device when you have internet connection.");
          return;
        }
        if (this.isBleTokenExpired(this.storedBleCredential)) {
          console.log("Token from storage expired");
          console.groupEnd();
          // reject("Token is expired, please connect to internet to renew token");
          this.$store.dispatch("setBleCredential", { device_id: plug_identifier, bleCredential: null });
          reject("You may not have access to this device, please try activating the device when you have internet connection.");
          return;
        }
        console.log("Using token from storage");
        console.groupEnd();
        this.$store.dispatch("setBleActivation", { tokens: this.storedBleCredential });
        resolve(this.storedBleCredential);
      });
    },
    async startConnectionInitialization(plug_identifier) {
      if (!plug_identifier) {
        this.$nextTick(() => this.$refs.deviceIdInput.focus());
        return;
      }
      this.$store.dispatch("setBleActivation", {
        activeBleDevice: plug_identifier,
        activeProcess: this.bleProcesses.CONNECTION_INITIALIZATION,
        plug_identifier: plug_identifier,
        busy: true,
        loading: true,
        forceConnectManually: false,
        forceStopProcess: false,
      });

      let get_token_error = false;
      this.$store.dispatch("setBleActivationModal", {
        title: "Connecting To Device",
        show: true,
        loading: "Verifying Connection...",
        error: null,
        okButton: null,
        cancelButton: null,
      });
      await this.getBleDeviceToken(plug_identifier).catch((error) => {
        console.error(error);
        this.$store.dispatch("setBleActivation", { busy: false });
        get_token_error = true;
        this.$store.dispatch("setBleActivationModal", { error: error, loading: null, okButton: "ok", cancelButton: null });
      });
      if (get_token_error) return;
      this.connectToBleDevice(plug_identifier);
    },
  },
};
