import Api from "@library/apis/Api";

export default {
  data() {
    return {};
  },
  methods: {
    bleApiChangeDeviceMode(plug_id, mode) {
      return new Promise((resolve, reject) => {
        Api.ownerPlugSetMode(`{"plugId":"${plug_id}", "mode":${mode}}`)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getOwnerBleToken(plug_id) {
      return new Promise((resolve, reject) => {
        Api.plugGetAsyncToken("owner", `{"plugIdentifier":"${plug_id}"}`)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getUserBleToken(plug_id) {
      return new Promise((resolve, reject) => {
        Api.plugGetAsyncToken("user", `{"plugIdentifier":"${plug_id}"}`)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    uploadUserBleSessions(plug_id, signature, sessionChunks) {
      return new Promise((resolve, reject) => {
        Api.plugPostAsyncSessions("user", `{"plugIdentifier":"${plug_id}", "signature":"${signature}","sessionsChunks":${sessionChunks}}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    uploadOwnerBleSessions(plug_id, signature, sessionChunks) {
      return new Promise((resolve, reject) => {
        Api.plugPostAsyncSessions("owner", `{"plugIdentifier":"${plug_id}", "signature":"${signature}","sessionsChunks":${sessionChunks}}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
