export default {
  methods: {
    processLog(session, sessionlogs, timezone, callback) {
      let data = sessionlogs || [];
      data.sort((a,b) => a.logitem_timestamp - b.logitem_timestamp);

      let span = data.length;
      let timeSpan = null; // mins

      if (timeSpan) {
        let oldData = data;
        let endData = oldData.pop();
        let till = oldData[oldData.length - 1].logitem_timestamp;
        let count = 0;
        let set = [];
        let newData = [];
        do {
          let time = this.$moment(session.starttime * 1000)
            .tz(timezone)
            .add(timeSpan * count, "minutes")
            .startOf("minute")
            .unix();
          set.push(time);
          count = count + 1;
          if (time > till) break;
        } while (count < span);

        set.forEach((value) => {
          let log = oldData.reduce((r, a, i, aa) => {
            return i && Math.abs(aa[r].logitem_timestamp - value) < Math.abs(a.logitem_timestamp - value) ? r : i;
          }, -1);
          if (log) newData.push(oldData[log]);
        });

        data = newData;
        data.push(endData);
      }

      data.unshift({
        consumption: 0,
        logitem_timestamp: session.starttime,
      });

      let day = this.$moment
        .unix(data[0].logitem_timestamp)
        .tz(timezone)
        .format("Md");
      data.forEach((o) => {
        let time = this.$moment.unix(o.logitem_timestamp).tz(timezone);
        let logDay = time.format("Md");
        if (day !== logDay) {
          day = logDay;
        }
        o.timestamp = time.format(session.graph_timeformat);
        o.consumptionkWh = o.consumption / 1000;
        return o;
      });

      callback(data);
      console.log("span: " + span);
      console.log("session log loaded");
    },
    testLog(
      start = this.$moment()
        .unix()
        .tz(timezone),
      to = 12
    ) {
      let logs = [];
      let count = 0;
      let time = start + 600;
      do {
        logs.push({
          consumption: count / 2,
          current: 1,
          id: null,
          logitem_timestamp: time + 600 * count,
          session_id: null,
          voltage: 0,
        });
        count = count + 1;
      } while (count <= to);
      return logs;
    },
  },
};
