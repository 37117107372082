import Api from "@library/apis/Api";
export default {
  data() {
    return {};
  },
  methods: {
    getBleToken(plug_id) {
      return new Promise((resolve, reject) => {
        Api.plugGetAsyncToken("user", `{"plugIdentifier":"${plug_id}"}`)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    uploadBleSessions(plug_id, signature, sessionChunks) {
      return new Promise((resolve, reject) => {
        Api.plugPostAsyncSessions("user", `{"plugIdentifier":"${plug_id}", "signature":"${signature}","sessionsChunks":${sessionChunks}}`)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
  },
};
