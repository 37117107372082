export default {
    data() {
        return {
            bleCommandHandler: {
                // activeCommand: {
                //     key: null,
                //     bleCommandEnum: null,
                //     bleCommandParams: null,
                // },
                retry: 0,
            },
        };
    },
    methods: {
        resetBleCommandVariables() {
            this.bleCommandHandler = {
                retry: 0,
            };
        },
        sendBleCommand(commandEnum, commandParams = { id: null, realm: null }) {
            return new Promise(async (resolve, reject) => {
                if (!this.ble.connectedDevice) {
                    reject("Device is not connected");
                    return;
                }
                if (this.ble.busy) {
                    // reject("Device is busy");
                    console.error("Device is busy");
                    setTimeout(() => {
                        this.sendBleCommand(commandEnum, commandParams)
                            .then((response) => resolve(response))
                            .catch((error) => reject(error));
                    }, 1000);
                    return;
                }

                // this.bleCommandHandler.activeCommand = {
                //     key: "send_command_to_device",
                //     bleCommandEnum: commandEnum,
                //     bleCommandParams: commandParams,
                // };
                const selectedCommand = Object.keys(this.bleCommands).find((key) => this.bleCommands[key] === commandEnum);
                console.groupCollapsed(`running command: ${selectedCommand} , params: ${JSON.stringify(commandParams)}`);
                let commandObj = {
                    expiry: this.bleToken.expiry,
                    token: this.bleToken.token,
                    command: commandEnum,
                    // realm: realm,
                    // id: this.bleToken.userId,
                    // paymentPlanID: this.bleToken.papSnapshotId,
                };
                for (const property in commandParams) {
                    commandObj[property] = commandParams[property];
                }
                console.log(commandObj);

                this.ble.busy = true;
                this.bleResponse.failed = 0;
                this.bleResponse.response = "";
                this.bleResponse.obj = "";

                let command_string = JSON.stringify(commandObj);
                let command = this.splitString(command_string, 18);
                let command_chunks = command.map((c, i) => (i == command.length - 1 ? "2" : "1") + c);
                command_chunks.unshift("0");
                const max_retry = window.ble_max_retries ? window.ble_max_retries : 3;
                console.groupCollapsed("Sending commands to device...");
                this.sendCommandToBleDevice(command_chunks)
                    .then((response) => {
                        console.log(response);
                        console.groupEnd();
                        console.groupEnd();
                        this.getBleResponse(commandObj)
                            .then((response) => resolve(response))
                            .catch((error) => reject(error));
                    })
                    .catch((error) => {
                        console.error("[" + new Date().getTime() + "] " + error);
                        console.groupEnd();
                        if (this.ble.forceStopProcess) {
                            reject("User stopped the process");
                            return;
                        }
                        if (this.bleCommandHandler.retry < max_retry) {
                            console.groupEnd();
                            console.warn("Retry to run BLE process, retry #" + (this.bleCommandHandler.retry + 1));
                            this.bleCommandHandler.retry++;
                            // this.retryBleProcess();
                            this.sendBleCommand(commandEnum, commandParams)
                                .then((response) => resolve(response))
                                .catch((error) => reject(error));
                        } else reject(error);
                    })
                    .finally(() => {
                        this.ble.busy = false;
                        console.groupEnd();
                    });
            });
        },
        sendCommandToBleDevice(command_chunks) {
            const timeout = window.ble_timeout ? window.ble_timeout : 3000;
            var _this = this;
            console.log(`Timeout for each packets (send and receive) : ${timeout}ms`);
            console.log(`Timeout for receiving packets : ` + (timeout - 500) + "ms");
            const promiseTimeout = function(ms, promise, error_msg = "") {
                let timeout = new Promise((resolve, reject) => {
                    let id = setTimeout(() => {
                        clearTimeout(id);
                        reject("Send packets timeout reached, " + error_msg);
                    }, ms);
                });
                return Promise.race([promise, timeout]);
            };

            function writeToCharacteristic(chunk) {
                console.log("[" + new Date().getTime() + "] Sending packet : " + chunk);
                return new Promise((resolve, reject) => {
                    if (!_this.ble.connectedDevice || !_this.ble.connectedDevice.gatt.connected) {
                        reject("Device not connected, process aborted");
                        return;
                    }
                    _this.bleResponse.response = "";
                    _this.bleConnection.rxCharacteristic
                        .writeValue(_this.prepareString(chunk))
                        .then(async () => {
                            let stop_execution = false;
                            function getResponse() {
                                return new Promise((getResponseResolve, getResponseReject) => {
                                    setTimeout(() => {
                                        if (_this.ble.forceStopProcess) {
                                            getResponseReject("BLE process cancelled");
                                            return;
                                        }
                                        if (stop_execution) return;
                                        if (!_this.bleResponse.response) {
                                            console.warn("[" + new Date().getTime() + `] Packet ${chunk} got no response, retry getting response...`);
                                            getResponse()
                                                .then((response) => getResponseResolve(response))
                                                .catch((error) => getResponseReject(error));
                                            return;
                                        }
                                        try {
                                            const isBleResponseValid = !(_this.bleResponse == "" || !_this.isJsonStringValid(_this.bleResponse));
                                            if (isBleResponseValid) {
                                                let res = JSON.parse(_this.bleResponse.response.replace(/\0/g, ""), true);
                                                if (res.status !== 0) {
                                                    if (res.status !== 8) {
                                                        getResponseReject("Packet sent error");
                                                        return;
                                                    }
                                                }
                                            }
                                        } catch (e) {
                                            getResponseReject("Packet sent error. Error : " + e);
                                            return;
                                        }
                                        getResponseResolve(_this.bleResponse.response);
                                    }, 100);
                                });
                            }

                            promiseTimeout(timeout - 500, getResponse(), "no response from device.")
                                .then((response) => {
                                    resolve(response);
                                })
                                .catch((error) => {
                                    stop_execution = true;
                                    reject(error);
                                });
                        })
                        .catch((err) => reject(err));
                });
            }

            return new Promise((resolve, reject) => {
                let index = -1;
                function sendNext() {
                    index++;
                    if (index < command_chunks.length) {
                        if (_this.ble.forceStopProcess) reject("BLE process cancelled");
                        promiseTimeout(timeout, writeToCharacteristic(command_chunks[index]), "error sending packets to device.")
                            .then((response) => {
                                setTimeout(() => sendNext(), 100); // delay send command, sometimes device not getting the command if send without delay
                            })
                            .catch((error) => reject(error));
                    } else {
                        resolve("Success to send commands");
                    }
                }
                sendNext();
            });
        },
    },
};
