import bleApiHandler from "./bleApiHandler";
import bleVariables from "./bleVariables";
import bleCommandHandler from "./bleCommandHandler";
import bleResponseHandler from "./bleResponseHandler";
import bleConnectionInitializationHandler from "./bleConnectionInitializationHandler";
import bleTokenHandler from "./bleTokenHandler";
export default {
    mixins: [bleApiHandler, bleVariables, bleResponseHandler, bleCommandHandler, bleConnectionInitializationHandler, bleTokenHandler],
    data() {
        return {
            ble: {
                nusServiceUUID: `6e400001-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharRXUUID: `6e400002-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharTXUUID: `6e400003-b5a3-f393-e0a9-e50e24dcca9e`,
                MTU: 20,
            },
        };
    },
    computed: {
        bleActivation() {
            return this.$store.getters.bleActivation;
        },
        bleActivationModal() {
            return this.$store.getters.bleActivationModal;
        },
        deviceName() {
            if (!this.bleActivation.device) return null;
            return this.bleActivation.device.name;
        },
        storedBleDevices() {
            return this.$store.getters.bleDevices;
        },
        storedBleCredential() {
            const selectedBleDevice = this.storedBleDevices.filter((bleDevice) => bleDevice.device_id == this.bleActivation.plug_identifier)[0];
            if (typeof selectedBleDevice == "undefined") return null;
            return selectedBleDevice.credential || null;
        },
        storedBleSessions() {
            const selectedBleDevice = this.storedBleDevices.filter((bleDevice) => bleDevice.device_id == this.bleActivation.plug_identifier)[0];
            if (typeof selectedBleDevice == "undefined") return [];
            return selectedBleDevice.sessions || [];
        },
        storedBleSyncTime() {
            const selectedBleDevice = this.storedBleDevices.filter((bleDevice) => bleDevice.device_id == this.bleActivation.plug_identifier)[0];
            if (typeof selectedBleDevice == "undefined") return 0;
            return selectedBleDevice.syncTime || 0;
        },
    },
    watch: {},
    methods: {
        finishBleProcess() {
            var _this = this;
            function updateBleDevices(show_device = true) {
                for (let index = 0; index < _this.storedBleDevices.length; index++) {
                    const device = _this.storedBleDevices[index];
                    if (device.device_id == _this.bleActivation.plug_identifier) {
                        _this.storedBleDevices[index].activated = show_device;
                        _this.storedBleDevices[index].session_active =
                            _this.bleActivation.activeProcess == _this.bleProcesses.START_SESSION ? true : false;
                        _this.$store.dispatch("setBleDevices", _this.storedBleDevices);
                        break;
                    }
                }
            }
            console.groupEnd();
            console.groupEnd();
            console.log("BLE process finished");
            switch (this.bleActivation.activeProcess) {
                case this.bleProcesses.CONNECTION_INITIALIZATION:
                    break;
                case this.bleProcesses.START_SESSION:
                    updateBleDevices();
                    break;
                case this.bleProcesses.STOP_SESSION:
                    updateBleDevices(false);
                    break;
                case this.bleProcesses.REMOVE_DEVICE:
                    updateBleDevices(false);
                    this.$store.dispatch("setBleActivation", { busy: false });
                    break;
            }
            this.$store.dispatch("setBleActivationModal", { error: null, loading: null, show: false });
            this.$store.dispatch("setBleActivation", { busy: false, loading: false });
            this.$store.dispatch("setBleActivationActiveCommand", { key: null, message: null, bleCommandEnum: null, bleCommandParams: {} });
        },
        onBleDeviceDisconnected() {
            if (this.bleActivation.deviceConnected && this.bleActivation.device) {
                this.bleActivation.device.removeEventListener("gattserverdisconnected", this.onBleDeviceDisconnected);
                this.resetBleDeviceState();

                for (let index = 0; index < this.storedBleDevices.length; index++) {
                    const device = this.storedBleDevices[index];
                    if (device.device_id == this.bleActivation.plug_identifier) {
                        this.storedBleDevices[index].session_active = false;
                        this.$store.dispatch("setBleDevices", this.storedBleDevices);
                        break;
                    }
                }
            }
            const deviceName = this.deviceName;
            this.$store.dispatch("setBleActivation", { device: null, deviceConnected: false, busy: false, connectedPlugIdentifier: null });
            console.log(deviceName ? deviceName + " Disconnected." : "");
        },
        resetBleDeviceState() {
            this.ble = {
                nusServiceUUID: `6e400001-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharRXUUID: `6e400002-b5a3-f393-e0a9-e50e24dcca9e`,
                nusCharTXUUID: `6e400003-b5a3-f393-e0a9-e50e24dcca9e`,
                MTU: 20,
            };
        },
        retryBleProcess(reset_retry_number = false) {
            const activeCommand = this.bleActivation.activeCommand;
            if (reset_retry_number) this.$store.dispatch("setBleActivation", { retry: 0 });
            this.$store.dispatch("setBleActivationModal", { error: null });
            switch (activeCommand.key) {
                case "send_command_to_device":
                    this.sendBleCommand(activeCommand.bleCommandEnum, activeCommand.bleCommandParams, activeCommand.message);
                    break;
                default:
                    this.$store.dispatch("setBleActivationModal", {
                        error: "Cannot retry command, please retry the process",
                        okButton: "ok",
                        cancelButton: null,
                    });
                    break;
            }
        },
        startOrStopBleSession() {
            let session_active = false;
            for (let index = 0; index < this.storedBleDevices.length; index++) {
                const device = this.storedBleDevices[index];
                if (device.device_id == this.bleActivation.plug_identifier) {
                    session_active = device.session_active;
                    break;
                }
            }
            this.bleActivation.activeProcess = session_active ? this.bleProcesses.STOP_SESSION : this.bleProcesses.START_SESSION;
            this.sendBleCommand(this.bleCommands.DEVICE_GET_CURRENT_STATE, {}, "Checking Device State");
        },
    },
};
