export default {
  methods: {
    lapse (start, end, format = ':mm:ss') {
      let ms = end - start
      let d = this.$moment.duration(ms)
      let h = Math.floor(d.asHours())
      if (h === 0) h = '00'
      let s = h + this.$moment.utc(ms).format(format)

      return s
    },
    ago (timestamp) {
      let duration = this.$moment().unix() - timestamp
      if (duration < 60) {
        return 'Just now'
      } else if (duration < 3600) {
        let mins = Math.ceil(duration / 60)
        return (mins > 1) ? `${mins} mins ago` : `${mins} min ago`
      } else if (duration < 86400) {
        let hours = Math.ceil(duration / 3600)
        return (hours > 1) ? `${hours} hrs ago` : `${hours} hr ago`
      } else {
        return this.$moment.unix(timestamp).format('DD-MMM-YYYY')
      }
    }
  }
}
