export default {
    data() {
        return {
            bleCommands: {
                WIFI_ADD_NETWORK: 0,
                WIFI_GET_STORED_NETWORKS: 1,
                WIFI_GET_CONNECTED_NETWORK: 2,
                WIFI_CLEAR_STORED_NETWORKS: 3,
                WIFI_REBOOT: 4,
                WIFI_SCAN: 5,
                VERIFY_CONNECTION: 6,
                DEVICE_REBOOT: 7,
                DEVICE_SET_ENDPOINT: 8,
                DEVICE_FORMAT_FLASH: 9,
                DEVICE_TRIGGER_HEARTBEAT: 10,
                DEVICE_GET_CURRENT_STATE: 11,
                DEVICE_SET_SYSTEM_TIME: 12,
                DEVICE_GET_SYSTEM_TIME: 13,
                DEVICE_SET_MODE: 14,
                DEVICE_GET_FIRMWARE_VERSION: 15,
                DEVICE_CHECK_FILE_SYSTEM_FREE_SPACE: 16,
                DEVICE_SET_EXTERNAL_ID: 17,
                BLE_START_SESSION: 18,
                BLE_STOP_SESSION: 19,
                BLE_GET_SESSION_LIST: 20,
                BLE_GET_SESSION_DATA: 21,
                BLE_CLEAR_SESSIONS: 22,
                ADE7953_CALIBRATE_VOLTAGE: 23,
                ADE7953_CALIBRATE_CURRENT: 24,
                ADE7953_CALIBRATE_POWER: 25,
                ADE7953_READ_RMS: 26,
            },
            bleProcesses: {
                CONNECTION_INITIALIZATION: 0,
                START_SESSION: 1,
                STOP_SESSION: 2,
                REMOVE_DEVICE: 3,
            },
            bleStates: {
                CHECKING_MODE: 0,
                WAITING_FOR_MODE_CHANGE: 1,
                CLAIMING_DEVICE: 2,
                ENABLE_MODE: 3,
                DISABLE_MODE: 4,
                SMART_MODE: 5,
                WAITING_FOR_AUTH: 6,
                STARTING_SESSION: 7,
                WAITING_FOR_SESSION_START: 8,
                MONITORING_POWER: 9,
                UPDATING_SESSION: 10,
                WAITING_FOR_SESSION_UPDATE: 11,
                STOPPING_SESSION: 12,
                WAITING_FOR_SESSION_STOP: 13,
                BLUETOOTH_MODE: 14,
                BLUETOOTH_WAITING_FOR_SESSION_START: 15,
                BLUETOOTH_STARTING_SESSION: 16,
                BLUETOOTH_MONITORING_POWER: 17,
                BLUETOOTH_STOPPING_SESSION: 18,
                CLEANING_UP: 19,
            },
            bleStatuses: {
                OK: 0,
                FAILED_VERIFICATION: 1,
                INVALID_JSON: 2,
                INVALID_BLE_COMMAND: 3,
                INVALID_DATA_COMMAND: 4,
                WIFI_NOT_CONNECTED: 5,
                WIFI_NO_CREDENTIALS: 6,
                WIFI_FAILED_TO_CLEAR_CREDENTIALS: 7,
                BLE_DISCONNECT: 8,
                ADE7953_CALIBRATION_FAILED: 9,
                INVALID_DEVICE_TYPE: 10,
                INVALID_STATE: 11,
                FUNCTION_NOT_IMPLEMENTED: 12,
                DEVICE_NO_FREE_SPACE: 13,
                BLE_SESSION_IN_PROGRESS: 14,
                SYSTEM_TIME_DELTA_EXCEEDED: 15,
            },
        };
    },
};
