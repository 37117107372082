import helpers from "./../../helpers";

export default {
    mixins: [helpers],
    data() {
        return {
            bleResponse: {
                failed: 0,
                log: null,
                obj: null,
                response: null,
            },
        };
    },
    methods: {
        getBleResponse(command) {
            var _this = this;
            let retry = false;
            function checkResponseValid(bleResponse) {
                const isBleResponseValid = !(bleResponse == "" || !_this.isJsonStringValid(bleResponse));
                console.log(bleResponse);
                console.log("Response JSON valid : " + isBleResponseValid);
                if (!isBleResponseValid) {
                    let acceptedTotalFailed = 5;
                    switch (command.command) {
                        case _this.bleCommands.BLE_GET_SESSION_DATA:
                            acceptedTotalFailed = 20;
                            break;
                        case _this.bleCommands.BLE_GET_SESSION_LIST:
                            acceptedTotalFailed = 10;
                            break;
                    }

                    if (_this.bleResponse.failed >= acceptedTotalFailed) {
                        console.error("no valid response from device");
                        return false;
                    }
                    retry = true;
                    return false;
                }
                return true;
            }

            return new Promise((resolve, reject) => {
                if (this.ble.forceStopProcess) {
                    console.log("BLE process cancelled");
                    reject({ error: "BLE process cancelled", response: null });
                    return;
                }
                console.groupCollapsed("Getting Response...");
                try {
                    this.bleResponse.response = this.bleResponse.response.replace(/\0/g, "");
                    if (!checkResponseValid(this.bleResponse.response)) {
                        if (retry) {
                            console.warn("response is either empty or not valid, retry getting response in 3 sec");
                            this.bleResponse.failed++;
                            setTimeout(() => {
                                this.getBleResponse(command)
                                    .then((response) => resolve(response))
                                    .catch((error) => reject(error));
                            }, 3000);
                        } else reject("Response is not valid");
                        console.groupEnd();
                        return;
                    }
                    console.log("Successfully get valid responses");
                    console.log(this.bleResponse.response);

                    this.bleResponse.failed = 0;
                    this.bleResponse.obj = JSON.parse(this.bleResponse.response, true);

                    let res = null;
                    res = this.bleResponse.obj;
                    console.log(res);

                    if (res.status !== this.bleStatuses.OK) throw "Failed to process command, please refresh the site and try again";

                    this.bleCommandHandler.retry = 0;
                    resolve(res);
                } catch (e) {
                    console.error(e);
                    reject(e.message);
                }
                this.ble.busy = false;
                console.groupEnd();
            });
        },
        handleBleNotifications(event) {
            let value = event.target.value;
            let str = "";
            for (let i = 0; i < value.byteLength; i++) {
                str += String.fromCharCode(value.getUint8(i));
            }
            try {
                const isBleResponseValid = !(this.bleResponse == "" || !this.isJsonStringValid(this.bleResponse));
                let res = null;
                if (isBleResponseValid) {
                    res = JSON.parse(str.replace(/\0/g, ""), true);
                }
                this.bleResponse.log = str;
                this.bleResponse.response = this.bleResponse.response + str.trim();
                this.bleResponse.obj = res;
            } catch (e) {
                console.log(e);
                return;
            }
            // console.log("[" + new Date().getTime() + "] Response received : " + str);
        },
        resetBleResponseVariables() {
            this.bleResponse = {
                failed: 0,
                log: null,
                obj: null,
                response: null,
            };
        },
    },
};
