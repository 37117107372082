import Api from "@/library/apis/Api";

export default {
  data() {
    return {};
  },
  methods: {
    isBleTokenExpired(token) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expiry = token.hasOwnProperty("expiry") ? token.expiry : -1;
      const expiredWithin = (expiry - currentTime) / 3600;
      if (expiredWithin < 0) {
        return true;
      }
      return false;
    },
    async isBleTokenUsedRecently(device_id, get_session_data = false, sessionLogs = []) {
      if (get_session_data && navigator.onLine) {
        console.groupCollapsed("Getting 30 days session logs for checking BLE token");
        const time = {
          since: this.$moment()
            .subtract(30, "days")
            .startOf("day")
            .unix(),
          till: this.$moment()
            .endOf("day")
            .unix(),
        };
        await Api.sessions("user", `{"since":${time.since},"till":${time.till}}`)
          .then((data) => {
            console.log("Successfully get session logs");
            sessionLogs = data;
          })
          .catch((error) => {
            console.log(error);
            console.log("Failed getting session logs. Using existing session logs.");
          });
        console.log(sessionLogs);
        console.groupEnd();
      }
      const tokenUsedSessions = sessionLogs.filter((sessionLog) => sessionLog.plug_identifier == device_id);
      if (tokenUsedSessions.length > 0) return true;
      return false;
    },
    isBleTokenExpiringSoon(token, expiringSoonThreshold = 12) {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expiry = token.hasOwnProperty("expiry") ? token.expiry : -1;
      const expiredWithin = (expiry - currentTime) / 3600;
      if (expiredWithin < 0) {
        return false;
      }
      if (expiredWithin <= expiringSoonThreshold) return true;
      return false;
    },
    async renewBleToken(device_id) {
      let returns = {
        error: false,
        message: "",
        token: null,
      };
      if (!navigator.onLine) {
        return {
          error: true,
          message: "Failed to get token, please connect to internet to continue",
          token: null,
        };
      }
      await this.getBleToken(device_id)
        .then((tokenResponse) => {
          this.$store.dispatch("setBleCredential", { device_id: device_id, bleCredential: tokenResponse });
          this.$store.dispatch("setBleSyncTime", { device_id: device_id, syncTime: tokenResponse.deleteTimestamp });
          this.$store.dispatch("setBleDevice", {
            device_id: device_id,
            properties: {
              paymentPlan: { ...tokenResponse.pap, currency: tokenResponse.currency },
              ownerPublicProfile: tokenResponse.ownerPublicProfile,
            },
          });
          returns.token = tokenResponse;
        })
        .catch((err) => {
          let message = "Failed to get token, please check your device id";
          if (err.response.status == 400) {
            message = err.response.data;
            console.log(message);
            if (message.includes("Access to this device is restricted by Property Management. For access, please contact Property Management.")) {
              this.$store.dispatch("setBleCredential", { device_id: device_id, bleCredential: null });
            }
          }
          returns = {
            error: true,
            message: message,
            token: null,
          };
        });
      return returns;
    },
  },
};
