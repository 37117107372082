import Api from "@library/apis/Api";

export default {
  data() {
    return {
      errors: {
        bluetoothTimebased: `Time-based payment plans cannot be applied to devices in Bluetooth mode!<br/>Please select simple payment plan.`,
        bluetoothTimebasedPower: `Time-based payment plans cannot be assigned to devices in Bluetooth mode. Please select a simple payment plan for the device's public payment plan.`,
        bluetoothTimebasedUsers: `Time-based payment plans cannot be assigned to users of devices in Bluetooth mode. Please change the payment plan of the following users.`,
      },
      plugModes: [
        {
          code: 2,
          name: "Smart",
          description: "Allows you to control access, set billing rates, monitor your device's status and power consumption.",
        },

        {
          code: 1,
          name: "Always On",
          description: "Just like a regular device. Anyone can plug in!",
        },
        {
          code: 0,
          name: "Always Off",
          description: "Disables your device. Plugging in doesn't do anything!",
        },
        // {
        //   code: 3,
        //   name: "Bluetooth",
        //   description: "Control your device through your phone without a network connection.",
        // },
      ],
      subModes: [
        {
          code: 0,
          name: "Private",
          description: "Only users chosen by you can access this device",
        },
        {
          code: 1,
          name: "Public",
          description: "Allows any Plugzio user to access this device",
        },
      ],
      modeChange: {
        id: null,
        identifier: null,
        username: null,
        ispublic: null,
        plan_id: null,
        code: null,
        // changeAccess: 0,
      },
      modeChangeModal: false,
      confirm: false,
      modeError: null,
      planError: null,
      selectError: null,
    };
  },
  computed: {
    selectedMode() {
      if (this.modeChange.code !== null) {
        return this.plugModes.find((o) => {
          return o.code === this.modeChange.code;
        });
      }
      return "";
    },
    accessDescription() {
      if (this.modeChange.ispublic) {
        return "Everyone can access the device on a default payment plan. Users who are granted specific access will use the assigned payment plan over the default plan.";
      } else if (this.modeChange.ispublic !== null) {
        return "No one can access the device unless granted access";
      }
      return "";
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    editMode(evt) {
      this.modeChange = {
        id: evt.id,
        identifier: evt.identifier,
        username: evt.active ? evt.active.username : null,
        ispublic: evt.ispublic ? 1 : 0,
        plan_id: evt.plan_id ? evt.plan_id : null,
        code: evt.plugmode > 3 ? 3 : evt.plugmode,
        // changeAccess: evt.changeAccess,
        plugmode: evt.plugmode,
      };
      this.modeChangeModal = true;
    },
    // delete this
    saveModeBackup() {
      if (this.modeChange.code > 1 && this.modeChange.ispublic && !this.modeChange.plan_id) {
        this.planError = true;
        return false;
      }
      const data = this.modeChange.ispublic ? `{"plugId":"${this.modeChange.id}","planId":"${this.modeChange.plan_id}"}` : `{"plugId":"${this.modeChange.id}"}`;

      this.requestModeChange(Api.plugSetAccess("owner", this.modeChange.ispublic ? "public" : "private", data));
    },
    requestModeChange(request) {
      this.$store.dispatch("loading", true);
      request
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("loading", false);
            this.$store.dispatch("snackbar", {
              message: "Device mode change request sent",
            });
            this.$store.dispatch("loadPlugs");
            this.$store.dispatch("editPlugUser", this.modeChange.id);
            this.modeChangeModal = false;
          }, 1500);
        })
        .catch((error) => {
          setTimeout(() => {
            console.log(error);
            this.$store.dispatch("loading", false);
            this.modeError =
              error.response.data === "Device has been disabled"
                ? `Device has been disabled due to expired subscription. Please contact customer support using the "Chat With Us" button on the navigation bar.`
                : error.response.data;
            this.$store.dispatch("loadPlugs");
          }, 1500);
        });
    },
    reload() {
      setTimeout(() => {
        this.$store.dispatch("loadPlugs");
      }, 1500);
    },
  },
  watch: {
    modeChangeModal() {
      this.modeError = null;
      this.planError = null;
      this.confirm = null;
    },
  },
};
