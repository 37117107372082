// TODO: Refactor this file, move it into store instead of mixins

import { mapState, mapGetters } from "vuex";
import RouteHelpers from "../library/helpers/RouteHelpers";
import Api from "../library/apis/Api";
import { Capacitor } from "@capacitor/core";
import { USER_ROLES } from "@/constants";
import { AUTHENTICATED_ROUTES, PUBLIC_ROUTES } from "@/constants"

export default {
  data() {
    return {
      error: [],
      access_token: localStorage.getItem("key"),
      // mode: null,
      knownRoutes: AUTHENTICATED_ROUTES,
      exemptRoutes: PUBLIC_ROUTES,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Auth", {
      roleSetting: "getRoleSetting",
      roleName: "getRoleName",
    }),
    loggedIn() {
      return this.access_token;
    },
    isDashboard() {
      return this.knownRoutes.includes(this.$route.name);
    },
    // local() {
    //   return !window.location.href.match(/my./) && !window.location.href.match(/manage\./);
    // },
    // production() {
    //   return !window.location.href.match(/localhost/) && !window.location.href.match(/demo-contractor/) && !window.location.href.match(/\/dev/);
    // },
    // isOwner() {
    //   return this.mode === "owner";
    // },
    isStagingApi() {
      return !!API_URL.match(/staging/);
    },
    testAppCode() {
      return this.$root.isMobileApplication && this.isStagingApi;
    },
    // modeName() {
    //   if (this.role) {
    //     let name =
    //       this.role
    //         .toString()
    //         .charAt(0)
    //         .toUpperCase() + this.role.slice(1);
    //     return name.replace("Owner", "Management");
    //   }
    //   return "";
    // },
  },
  methods: {
    checkURL() {
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
      this.$store.dispatch("Auth/setRole");

      this.$nextTick(() => {
        document.body.classList.remove("admin-mode");
        document.body.classList.remove("user-mode");
        document.body.classList.remove("owner-mode");
        document.body.classList.add(`${this.role}-mode`);

        metaThemeColor.setAttribute("content", this.roleSetting.color);
      });
    },
    switchMode() {
      if (!RouteHelpers.isLocal()) {
        let url = window.location.href;
        if (this.role == "owner") {
          url = url.replace("my.", "manage.");
        } else if (this.role == "user") {
          url = url.replace("manage.", "my.");
        }
        window.location = url;
      }
    },
    setToken() {
      this.access_token = localStorage.getItem("key");
      // if (this.access_token) axios.defaults.headers.common["X-jwt"] = this.access_token;
    },
    async userLogin({ username, password, remember } = {}, errorCallback) {
      localStorage.removeItem("__plugzio_user_credentials__")

      await Api.login(
        this.role,
        {
          grant_type: "client_credentials",
          client_id: username,
          client_secret: password,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      )
        .then((key) => {
          if (remember) localStorage.setItem("__plugzio_user_credentials__", JSON.stringify({ username: decodeURIComponent(username), password: decodeURIComponent(password) }))
          localStorage.setItem("key", key);
          localStorage.setItem("email", decodeURIComponent(username));
          this.$store.commit("Auth/setState", { accessToken: key, email: decodeURIComponent(username) });
          this.setToken();

          this.$nextTick(() => {
            let redirectTo = `${this.roleSetting.routePrefix}dashboard`
            if (this.role === USER_ROLES.OWNER) redirectTo = "owner-account-selection"
            this.$router.push({ name: redirectTo });
            if (localStorage.getItem("__plugzio_user_credentials__")) { 
              this.$store.dispatch("snackbar", {
                message: "Login credentials saved",
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
          errorCallback();
        });
    },
    logout() {
      this.$store.dispatch("resetCount");
      this.$store.dispatch("setPlugClosestExpirationTimestamp", null);
      this.$store.commit("Global/setState", { preferences: {} })
      this.$store.dispatch("resetState");
      localStorage.removeItem("key");
      localStorage.removeItem("email");
      localStorage.removeItem("skip");
      localStorage.removeItem("firebaseToken");
      localStorage.removeItem("verify");
      localStorage.removeItem("__plugzio_plug_expiration_notification_dismissed__");
      localStorage.removeItem("__mobikwik_tokens__");
      this.setToken();
      this.$store.commit("Auth/setState", { accessToken: null, email: null });
      this.$router.push({ name: `${this.roleSetting.routePrefix}login` });
    },
  },
  watch: {
    $route() {
      this.checkURL();
    },
  },
  mounted() {
    this.checkURL();
    if (!this.exemptRoutes.includes(this.$route.name) && !this.loggedIn) this.$router.push({ name: `${this.roleSetting.routePrefix}login` });
    this.setToken();
  },
};
