import helpers from "./../../mixins/helpers";

export default {
  mixins: [helpers],
  data() {
    return {};
  },
  computed: {
    bleResponse() {
      return this.$store.getters.bleResponse;
    },
  },
  methods: {
    getBleResponse(command) {
      if (this.bleActivation.forceStopProcess) {
        console.log("BLE process cancelled");
        return;
      }
      var _this = this;
      function checkResponseValid(bleResponse) {
        const isBleResponseValid = !(bleResponse == "" || !_this.isJsonStringValid(bleResponse));
        console.log(bleResponse);
        console.log("Response JSON valid : " + isBleResponseValid);
        if (!isBleResponseValid) {
          let acceptedTotalFailed = 5;
          switch (command.command) {
            case _this.bleCommands.BLE_GET_SESSION_DATA:
              acceptedTotalFailed = 20;
              break;
            case _this.bleCommands.BLE_GET_SESSION_LIST:
              acceptedTotalFailed = 10;
              break;
          }

          if (_this.bleResponse.failed >= acceptedTotalFailed) {
            console.error("no valid response from device");
            _this.$store.dispatch("setBleActivationModal", {
              error: "No valid responses from device, please try again",
              loading: null,
              title: "Error getting response",
              okButton: "ok",
              cancelButton: null,
            });
            return;
          }
          console.warn("response is either empty or not valid, retry getting response in 3 sec");
          console.groupEnd();
          _this.$store.dispatch("setBleResponse", { failed: _this.bleResponse.failed + 1 });
          setTimeout(() => _this.getBleResponse(command), 3000);
          return false;
        }
        return true;
      }
      function updateBleDevice(device_id, payload) {
        for (let index = 0; index < _this.storedBleDevices.length; index++) {
          const device = _this.storedBleDevices[index];
          if (device.device_id == device_id) {
            for (const property of Object.keys(payload)) {
              _this.storedBleDevices[index][property] = payload[property];
            }
            _this.$store.dispatch("setBleDevices", _this.storedBleDevices);
            break;
          }
        }
      }
      function responseError(errorMessage) {
        _this.$store.dispatch("setBleActivationModal", { error: errorMessage, loading: null });
      }

      this.$nextTick(async () => {
        console.groupCollapsed("Getting Response...");
        try {
          const bleResponse = this.bleResponse.response.replace(/\0/g, "");
          if (!checkResponseValid(bleResponse)) {
            return;
          }
          console.log("Successfully get valid responses");
          console.log(bleResponse);
          this.$store.dispatch("setBleResponse", { failed: 0, obj: JSON.parse(bleResponse, true) });

          let res = null;
          res = this.bleResponse.obj;
          console.log(res);
          if (res.status === this.bleStatuses.BLE_SESSION_IN_PROGRESS) throw `Device ${this.bleActivation.plug_identifier} is currently in use. Please try again later.`;

          if (res.status !== this.bleStatuses.OK) throw "Failed to process command, please refresh the site and try again";

          switch (command.command) {
            case this.bleCommands.VERIFY_CONNECTION:
              this.sendBleCommand(this.bleCommands.BLE_CLEAR_SESSIONS, { data: { time: this.storedBleSyncTime } }, "Getting Session Data...");
              break;
            case this.bleCommands.BLE_CLEAR_SESSIONS:
              if (typeof res.data == "undefined") responseError("Failed to delete unused datas");

              var deviceSessions = this.storedBleSessions;
              res.data.forEach((data) => {
                deviceSessions = deviceSessions.filter((session) => session.startTime != data[0] && session.dataCount != data[1] && session.userId != data[2] && session.paymentPlanID != data[3]);
              });
              updateBleDevice(this.bleActivation.plug_identifier, { sessions: deviceSessions });
              switch (this.bleActivation.activeProcess) {
                case this.bleProcesses.STOP_SESSION:
                case this.bleProcesses.REMOVE_DEVICE:
                  this.finishBleProcess();
                  break;
                default:
                  this.sendBleCommand(this.bleCommands.BLE_GET_SESSION_LIST, {}, "Getting Session Data...");
                  break;
              }
              break;
            case this.bleCommands.BLE_GET_SESSION_LIST:
              if (typeof res.data == "undefined") responseError("Failed to get device stored sessions");

              var deviceSessions = [];
              res.data.forEach((data) => {
                deviceSessions.push({
                  startTime: data[0],
                  dataCount: data[1],
                  userId: data[2],
                  paymentPlanID: data[3],
                  data: [],
                });
              });
              updateBleDevice(this.bleActivation.plug_identifier, { sessions: deviceSessions });
              if (deviceSessions.length == 0) {
                this.sendBleCommand(this.bleCommands.DEVICE_GET_CURRENT_STATE, {}, "Checking Device State");
                break;
              }

              this.sendBleCommand(
                this.bleCommands.BLE_GET_SESSION_DATA,
                {
                  data: {
                    index: deviceSessions.length - 1,
                  },
                },
                "Getting Session Data..."
              );
              break;
            case this.bleCommands.BLE_GET_SESSION_DATA:
              if (typeof res.chunks == "undefined") responseError("Failed to get device stored sessions");

              var deviceSessions = this.storedBleSessions;

              let sessions = JSON.parse(res.chunks.join(""));
              for (let index = 0; index < sessions.length; index++) {
                const session = sessions[index];
                if (typeof session.data == "undefined") continue;
                deviceSessions[index].data = session.data.map((data) => ({
                  consumption: data[0],
                  voltage: data[1],
                  current: data[2],
                  timeDelta: data[3],
                }));
              }
              const sessionChunks = JSON.parse(bleResponse, function(key, value) {
                if (key == "chunks") return JSON.stringify(value);
                return value;
              }).chunks;
              try {
                for (let device_index = 0; device_index < this.storedBleDevices.length; device_index++) {
                  let uploaded = false;
                  const bleDevice = this.storedBleDevices[device_index];
                  const signature = bleDevice.device_id == this.bleActivation.plug_identifier ? res.signature : bleDevice.signature;
                  const chunks = bleDevice.device_id == this.bleActivation.plug_identifier ? sessionChunks : bleDevice.chunks;
                  const sessions = bleDevice.device_id == this.bleActivation.plug_identifier ? deviceSessions : bleDevice.sessions;
                  if (navigator.onLine && sessions.length > 0 && chunks && signature) {
                    await this.uploadBleSessions(bleDevice.device_id, signature, chunks)
                      .then(async (response) => {
                        uploaded = true;
                        await this.getBleToken(bleDevice.device_id).then((token) => {
                          if (bleDevice.device_id == this.bleActivation.plug_identifier) {
                            this.$store.dispatch("setBleActivation", { tokens: token });
                            this.$store.dispatch("setTime", {
                              since: this.timeSpan.since,
                              till: token.deleteTimestamp,
                            });
                          }
                          this.$store.dispatch("setBleCredential", {
                            device_id: bleDevice.device_id,
                            bleCredential: token,
                          });
                          this.$store.dispatch("setBleSyncTime", {
                            device_id: bleDevice.device_id,
                            syncTime: token.deleteTimestamp,
                          });
                        });
                      })
                      .catch((error) => {
                        console.log("Upload session data failed.");
                      });
                  }

                  let payload = { sessions: [], signature: null, chunks: null };
                  if (!uploaded) payload = { sessions: sessions, signature: signature, chunks: chunks };
                  updateBleDevice(bleDevice.device_id, payload);
                }
              } catch (error) {
                console.log(error);
              }
              if (![this.bleProcesses.STOP_SESSION, this.bleProcesses.REMOVE_DEVICE].includes(this.bleActivation.activeProcess)) {
                this.sendBleCommand(this.bleCommands.DEVICE_GET_CURRENT_STATE, {}, "Checking Device State");
                break;
              }
              this.sendBleCommand(this.bleCommands.BLE_CLEAR_SESSIONS, { data: { time: this.storedBleSyncTime } }, "Getting Session Data...");
              break;

            case this.bleCommands.DEVICE_GET_CURRENT_STATE:
              const currentState = res.data.CurrentState;
              if (typeof currentState == "undefined") {
                responseError("Failed to check device state, please refresh and try again");
                break;
              }
              switch (currentState) {
                case this.bleStates.BLUETOOTH_WAITING_FOR_SESSION_START:
                  if ([this.bleProcesses.STOP_SESSION, this.bleProcesses.REMOVE_DEVICE].includes(this.bleActivation.activeProcess)) {
                    this.finishBleProcess();
                    break;
                  }
                  switch (this.bleActivation.activeProcess) {
                    case this.bleProcesses.CONNECTION_INITIALIZATION:
                      this.$store.dispatch("setBleActivationModal", {
                        title: "Payment Plan",
                        loading: null,
                        cancelButton: "declinepaymentplan",
                        okButton: "confirmpaymentplan",
                        process: "paymentplan",
                      });
                      break;
                    case this.bleProcesses.START_SESSION:
                      this.$store.dispatch("setBleActivationModal", {
                        loading: null,
                        cancelButton: "dontstartsessionandremovedevice",
                        okButton: "startsession",
                        message: "No Active Session. Would you like to start a session?",
                      });
                      break;
                  }
                  break;
                case this.bleStates.BLUETOOTH_MODE:
                case this.bleStates.BLUETOOTH_STARTING_SESSION:
                case this.bleStates.BLUETOOTH_MONITORING_POWER:
                case this.bleStates.BLUETOOTH_STOPPING_SESSION:
                case this.bleStates.CLEANING_UP:
                  if (![this.bleProcesses.STOP_SESSION, this.bleProcesses.REMOVE_DEVICE].includes(this.bleActivation.activeProcess)) {
                    updateBleDevice(this.bleActivation.plug_identifier, {
                      activated: true,
                      session_active: true,
                    });
                    this.finishBleProcess();
                    break;
                  }
                  this.sendBleCommand(this.bleCommands.BLE_STOP_SESSION, {}, "Stopping session");
                  break;
                default:
                  throw `This device is in an invalid power settings. Please contact management for more information or try again later.`;
              }
              break;
            case this.bleCommands.DEVICE_CHECK_FILE_SYSTEM_FREE_SPACE:
              const freeSpace = res.data.bytes;
              const minimumSpace = 0;
              if (typeof freeSpace == "undefined" || freeSpace <= minimumSpace) {
                responseError("Device out of memory, please connect internet to clear out memory");
                break;
              }
              this.sendBleCommand(this.bleCommands.BLE_START_SESSION, { data: { time: Math.floor(new Date().getTime() / 1000) } }, "Starting session");
              break;
            case this.bleCommands.BLE_START_SESSION:
              updateBleDevice(this.bleActivation.plug_identifier, {
                activated: true,
                session_active: true,
                startTime: Math.round(new Date().getTime() / 1000),
              });
              this.finishBleProcess();
              break;
            case this.bleCommands.BLE_STOP_SESSION:
              updateBleDevice(this.bleActivation.plug_identifier, {
                activated: true,
                session_active: false,
                endTime: Math.round(new Date().getTime() / 1000),
              });
              this.$store.dispatch("setBleActivation", { busy: false });
              setTimeout(() => this.sendBleCommand(this.bleCommands.BLE_GET_SESSION_LIST, {}, "Getting Session Data..."), 1000);
              break;
          }
          this.$store.dispatch("setBleActivation", { busy: false, retry: 0 });
        } catch (e) {
          this.$store.dispatch("setBleActivationModal", {
            // error: `Failed to process command, please refresh the site and retry`,
            error: e,
            loading: false,
            okButton: "ok",
            cancelButton: null,
          });
          this.$store.dispatch("setBleActivation", {
            busy: false,
            loading: false,
          });
          console.error(e);
        }
        console.groupEnd();
      });
    },
    handleBleNotifications(event) {
      let value = event.target.value;
      let str = "";
      for (let i = 0; i < value.byteLength; i++) {
        str += String.fromCharCode(value.getUint8(i));
      }
      try {
        const isBleResponseValid = !(this.bleResponse == "" || !this.isJsonStringValid(this.bleResponse));
        let res = null;
        if (isBleResponseValid) {
          res = JSON.parse(str.replace(/\0/g, ""), true);
        }
        this.$store.dispatch("setBleResponse", { log: str, response: this.bleResponse.response + str.trim(), obj: res });
      } catch (e) {
        console.log(e);
        return;
      }
      // console.log("[" + new Date().getTime() + "] Response received : " + str);
    },
  },
};
